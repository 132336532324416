<template>
    <div class="marketTypeManagement">
        <div class="mm-title">
            <div class="title">销售类型</div>
            <div class="add" @click="clickAddBtn">新增销售类型</div>
        </div>
        <div class="mm-content">
            <div class="mc-item" v-for="(item,index) in list" :key="index">
                <div class="i-list">{{item.name}}</div>
                <div class="i-btn" v-if="item.id != ''">
                    <div class="btn" @click="clickEdit(item)">修改</div>
                    <div class="btn" @click="clickDel(item)">删除</div>
                </div>
            </div>
        </div>
        <!-- 添加 -->
        <el-dialog
            :visible.sync="isShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px">
            <div class="class-wrap">
                <div class="cw-title">{{isShowMD?'编辑销售类型':'新增销售类型'}}</div>
                <div class="cw-list">
                    <el-input v-model="className" placeholder="请输入销售类型名称"></el-input>
                </div>
                <div class="cw-btn" @click="clickAddMain">确定</div>
            </div>
        </el-dialog>
        <!-- 提示 -->
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">删除【{{delName}}】后{{delName}}人员将归为无类型</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'marketTypeManagement',
            isShow: false,
            isShowMD: false,
            isDel: false,
            className: '',
            list: [],
            delIndex: '',
            delName: '',
            classEditId: ''
        }
    },
    mounted () {
        this.saleTypeWhole()
    },
    methods: {
        // 获取销售类型
        saleTypeWhole(){
            api.get('/service/sale/get_sale_type_whole', {}, (res) =>  {
                this.list = res.data.list
            });
        },
        // 删除
        clickDel(item){
            this.delIndex = item.id
            this.delName = item.name
            this.isDel = true
        },
        clickConfirm(){
            let params = {
                id: this.delIndex
            }
            api.post('/service/sale/sale_type_del', params, (res) =>  {
                this.delIndex = '';
                this.$message.success('已删除')
                this.saleTypeWhole()
            });
            this.isDel = false
        },
        // 修改
        clickEdit(item){
            this.className = item.name
            this.classEditId = item.id
            this.isShow = true
            this.isShowMD = true
        },
        // 新增销售类型
        clickAddBtn(){
            this.isShow = true
            this.className = '';
            this.isShowMD = false
        },
        // 确定添加
        clickAddMain(){
            if(this.className == ''){
                this.$message.error('请输入销售类型名称')
                return
            }
            if(this.isShowMD){
                // 编辑
                let params = {
                    id: this.classEditId,
                    name: this.className
                }
                api.post('/service/sale/sale_type_edit', params, (res) =>  {
                    this.saleTypeWhole()
                    this.$message.success('已修改')
                    this.className = '';
                    this.classEditId = '';
                    this.isShow = false
                });
            }else{
                // 添加
                let params = {
                    name: this.className
                }
                api.post('/service/sale/sale_type_add', params, (res) =>  {
                    this.saleTypeWhole()
                    this.$message.success('已新增')
                    this.className = '';
                    this.isShow = false
                });
            }
        },
    },
}
</script>

<style lang='scss'>
.marketTypeManagement{
    .mm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .title{
            font-size: 24px;
            color: #101010;
        }
        .add{
            width: 140px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid #0068ff;
            font-size: 14px;
            color: #0068ff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .mm-content{
        padding: 20px 40px;
        .mc-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 500px;
            height: 60px;
            border-radius: 8px;
            background: #fff;
            padding: 0 30px;
            margin-bottom: 15px;
            .i-list{
                font-size: 16px;
                color: #101010;
            }
            .i-btn{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .btn{
                    margin-left: 40px;
                    font-size: 15px;
                    color: #0068ff;
                    cursor: pointer;
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .cw-list{
            padding: 20px;
        }
        .cw-btn{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-size: 15px;
            color: #fff;
            background: #0068ff;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
</style>
